.mobileCopyNotification {
  position: fixed;
  bottom: 40px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.mobileCopyNotificationContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
